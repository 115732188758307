<template>
  <div class='flex flex-col w-full'>
    <div class='flex flex-col justify-center bg-white items-center'>
      <!-- Header -->
      <div class='flex flex-col md:flex-row pt-10 pb-1 max-w-7xl'>
        <!-- Main Text -->
        <div class='max-w-sm flex flex-col justify-center md:max-w-2xl ml-4 md:ml-0 md:mr-10'>
          <Carousel
            :perPage="1"
            :scrollPerPage="false"
            :perPageCustom="[[768, 1], [1024, 1], [1280, 1], [1536, 1]]"
            :autoplay="true"
            :autoplayTimeout="5000"
            :loop='true'
            autoplayHoverPause
            :paginationEnabled="showPagination"
            paginationColor="#F3F4F6"
            paginationActiveColor='#000000'
            class="pr-6"
            v-model="startIndex"
          >
            <!-- <Slide>
              <div class='text-3xl font-black uppercase md:text-5xl text-primary'>
                <div>
                  Cannot find specific job salary?
                </div>
                <div class='tracking-normal mt-0 md:mt-8 md:text-5xl'>
                  Use <span class="text-red-700">MRC Super-Comp</span>:
                </div>
                <div class='mt-0 md:mt-2 md:mb-4 md:text-4xl text-yellow-500'>
                  A machine-learned compensation data
                </div>
              </div>
              <div class='h-16 my-5 mt-5 mr-4 md:mr-0'>
                <div class='px-4 py-2 text-xl md:text-xl font-medium text-center text-yellow-100 rounded shadow-md bg-red-800 font-robot'>
                  MRC powered by AI & human tacit knowledge
                </div>
              </div>
            </Slide>
            <Slide>
              <div class='text-3xl font-black uppercase md:text-6xl text-primary'>
                <div>
                  <span class="text-red-700">Super-comp predicts</span>
                </div>
                <div class='mt-0 md:mt-2 md:text-5xl'>
                  1300+ Jobs
                </div>
                <div class='mt-0 md:mt-2 md:text-5xl'>
                  100+ Countries
                </div>
                <div class='mt-0 md:mt-2 md:text-5xl'>
                  60+ Industries
                </div>
              </div>
              <div class='h-16 my-5 mt-5 mr-4 md:mr-0'>
                <div v-if="hTextshow2" class='px-4 py-2 text-xl md:text-xl font-medium text-center text-yellow-100 rounded shadow-md bg-red-800 font-robot'>
                  MRC powered by AI & human tacit knowledge
                </div>
              </div>
            </Slide>
            <Slide>
              <div class='text-3xl font-black uppercase md:text-6xl text-primary'>
                <div>
                  Get Benchmarking Market Data
                </div>
                <div class='tracking-normal mt-0 md:mt-8 md:text-5xl'>
                  <span class="text-red-700">Top Management & Function Head</span>
                </div>
                <div class='tracking-normal mt-0 md:mt-2 md:text-4xl'>
                  <span class="text-blue-700">Expat, Hybrid & Local</span>
                </div>
                <div class='tracking-normal mt-0 md:mt-2 md:text-4xl'>
                  <span class="text-yellow-500">18 APAC Locations</span>
                </div>
                <div class='tracking-normal mt-0 md:mt-2 md:text-4xl'>
                  <span class="text-yellow-500">200+ Companies</span>
                </div>
              </div>
              <div class='h-16 my-5 mt-5 mr-4 md:mr-0'>
                <div v-if="hTextshow2" class='px-4 py-2 text-xl md:text-xl font-medium text-center text-yellow-100 rounded shadow-md bg-red-800 font-robot'>
                  MRC powered by AI & human tacit knowledge
                </div>
              </div>
            </Slide> -->
            <Slide>
              <div class='text-3xl font-black uppercase md:text-6xl text-primary'>
                <div class='text-yellow-500'>
                  Total Rewards,
                </div>
                <div class='mt-0 md:mt-2 text-blue-700'>
                  Predictive Analytics,
                </div>
                <div class='tracking-normal mt-0 md:mt-2'>
                  Organisational Strategy Consulting
                </div>
              </div>
              <div class='h-16 my-5 mt-5 mr-4 md:mr-0'>
                <div v-if="hTextshow2" class='px-4 py-2 text-xl md:text-xl font-medium text-center text-yellow-100 rounded shadow-md bg-red-800 font-robot'>
                  MRC powered by AI & human tacit knowledge
                </div>
              </div>
              <!-- <div class='text-3xl font-black uppercase md:text-6xl text-primary'>
                <div class='tracking-normal'>
                  <span class="inline-flex overflow-hidden animate-type group-hover:animate-type whitespace-nowrap text-brand-accent will-change-transform font-header text-yellow-500">
                    Total Rewards,
                  </span>
                  <span v-if="!hTextshow1" class="box-border inline-block w-1 h-6 ml-2 -mb-1 bg-black md:-mb-2 md:h-14 animate-cursor will-change-transform"></span>
                </div>
                <div class='tracking-normal mt-0 md:mt-2'>
                  <span v-if="!hTextshow1" class="text-white font-header">Predictive Analytics</span>
                  <Transition name="fade">
                    <span v-if="hTextshow1" class="font-header text-blue-700">Predictive Analytics</span>
                  </Transition>
                </div>
                <div class='tracking-normal mt-0 md:mt-2'>
                  <span v-if="!hTextshow2" class="text-white font-header">& Organisational Strategy Consulting</span>
                  <Transition name="fade">
                    <span v-if="hTextshow2" class="font-header">& Organisational Strategy Consulting</span>
                  </Transition>
                </div>
              </div>
              <div class='h-16 my-5 mt-5 mr-4 md:mr-0'>
                <div v-if="hTextshow2" class='px-4 py-2 text-xl md:text-xl font-medium text-center text-yellow-100 rounded shadow-md bg-red-800 font-robot tracking-wide animate-stamp will-change-transform'>
                  MRC powered by AI & human tacit knowledge
                </div>
              </div> -->
            </Slide>
          </Carousel>
        </div>
        <!-- Main Text -->
        <!-- Highlight Activity -->
        <div class='flex md:justify-end'>
          <div class='bg-dots'>
            <!-- Main Event -->
            <div class='z-10 w-auto mx-2 mt-8 md:mt-0 md:ml-4 rounded-full shadow-2xl'
            v-for='(f, i) in features' :key='i'>
              <div class='relative'>
                <img
                  alt='card img'
                  class='rounded-t'
                  :src='f.img'
                />
                <div class='absolute bottom-0 left-0 right-0 px-4 py-2 bg-gray-800 opacity-70'>
                  <h3 class='text-xl text-white font-serif'>
                    {{ f.title }}
                  </h3>
                </div>
              </div>
              <div class='p-2 text-2xl bg-white'>
                <div class='flex flex-col px-2 py-4 sm:flex-row'>
                  <div class="flex w-full md:w-3/4">
                    <span class='text-sm text-white bg-red-600 md:block py-2 px-4 rounded-full'>
                      {{ f.subTitle }}
                    </span>
                    <span
                      v-for='(tag, t) in f.tags'
                      :key='t'
                      class='text-gray-600 bg-gray-100 text-sm md:block py-2 px-2 rounded-full ml-2'
                    >
                      {{ tag }}
                    </span>
                  </div>
                  <div
                    v-for='(d, j) in f.buttons'
                    :key='j'
                    class="flex justify-end w-full md:w-1/4 mt-2 md:mt-0"
                  >
                    <div
                      v-if='d.canDownload'
                      class='text-sm text-white ml-2 bg-blue-600 md:block py-2 px-4 rounded-full hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                    >
                      <a
                        :href='d.download'
                        target='_blank'
                      >
                        {{ d.downloadTxt }}
                      </a>
                    </div>
                    <div
                      @click='showRequestModal(f)'
                      v-else
                      class='text-sm text-white ml-2 bg-blue-600 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                    >
                      {{ d.downloadTxt }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Main Event -->
          </div>
        </div>
        <!-- Highlight Activity -->
      </div>
      <!-- More Events -->
      <div class="grid md:grid-cols-2 md:flex-row max-w-7xl w-full">
        <!-- col -->
        <div class='flex flex-col justify-center md:max-w-2xl ml-2 mr-2 md:ml-0 md:mr-0'>
          <div class='mt-6 text-sm font-medium text-center text-gray-500'>
            <div class="text-left text-black">
              <h2 class='text-3xl font-header'>
                Latest Articles/Updates
              </h2>
            </div>
            <div class="h-auto md:h-80 overflow-x-auto">
              <div
                v-for='(f, k) in featuresArticles'
                :key='k'
              >
                <!-- Card -->
                <div
                  class='relative overflow-hidden w-full bg-white items-center border-b-4 border-gray-200 dark:bg-slate-800 mt-6 dark:highlight-white/5 text-left'
                >
                  <div class='flex flex-col pt-0 w-full'>
                    <div>
                      <span
                        v-for='(tag, t) in f.tags'
                        :key='t'
                        class='text-blue-600 text-base py-2 px-0 ml-2'
                      >
                        {{ tag }}
                      </span>
                    </div>
                    <div class="flex pl-2">
                      <h5 class='text-base md:text-xl font-medium font-robot text-gray-900 dark:text-white text-left'>
                        {{ f.title }}
                      </h5>
                    </div>
                    <div class='flex flex-col px-2 md:px-4 md:pt-2 py-0 pb-2 sm:flex-row items-center'>
                      <div class="flex w-full md:w-1/2">
                        <div class="text-lg flex items-center">
                          <svg class="opacity-75 mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="12" height="12" viewBox="0 0 97.16 97.16" style="enable-background:new 0 0 97.16 97.16;" xml:space="preserve">
                            <path d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823    c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z"/>
                            <path d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832    c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z"/>
                          </svg>
                          <p class="leading-none text-red-600">{{ f.subTitle }}</p>
                        </div>
                      </div>
                      <div class="flex justify-end w-full md:w-1/2">
                        <div
                          v-for='(d, j) in f.buttons'
                          :key='j'
                        >
                          <div
                            v-if='d.canDownload'
                            class='text-sm text-white ml-2 bg-blue-600 md:block py-2 px-4 rounded-full hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                          >
                            <a
                              :href='d.download'
                              target='_self'
                            >
                              {{ d.downloadTxt }}
                            </a>
                          </div>
                          <div
                            @click='showRequestModal(f)'
                            v-else
                            class='text-sm text-black bg-white border border-gray-300 ml-2 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                          >
                            {{ d.downloadTxt }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Card -->
              </div>
            </div>
          </div>
        </div>
        <!-- col -->
        <!-- col -->
        <div class='mt-6 text-sm font-medium text-center text-gray-500 w-full pl-2 pr-2 md:pl-10 md:pr-0'>
          <!-- Tabs -->
          <ul class='flex flex-wrap -mb-px w-full'>
            <li class='mr-2' v-for="(h, j) in moreHighlights" :key='j'>
              <a
                v-if='selectedHighlight==h'
                class='inline-block p-4 text-blue-600 text-base md:text-lg border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 cursor-pointer'
                @click='selectedHighlight=h'
              >
                {{ h }}
              </a>
              <a
                v-if='selectedHighlight!=h'
                class='inline-block p-4 text-base md:text-lg border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 cursor-pointer'
                @click='selectedHighlight=h'
              >
                {{ h }}
              </a>
            </li>
          </ul>
          <!-- Tabs -->
          <div class="h-auto md:h-80 overflow-x-auto w-full">
            <div v-for="(h, j) in moreHighlights" :key='j'>
              <div v-if="selectedHighlight == h">
                <div
                  v-for='(f, k) in getFeaturesMore(h)'
                  :key='k'
                >
                  <!-- Card -->
                  <div
                    class='relative overflow-hidden w-full bg-white rounded-xl items-center dark:bg-slate-800 h-36 md:h-32 mt-6 dark:highlight-white/5 shadow-md'
                  >
                    <img
                      class='absolute -left-16 -top-4 md:-left-6 md:-top-2 w-44 h-44 md:w-32 md:h-36 rounded-full'
                      :src='f.img'
                    />
                    <div class='flex flex-row w-full'>
                      <div class='flex flex-col pl-32 pt-0 w-full'>
                        <div class="flex flex-col h-20 pl-2">
                          <h5 class='text-base md:text-lg font-medium text-gray-900 dark:text-white text-left'>
                            {{ f.title }}
                          </h5>
                          <small v-if='f.text' class="text-left">{{ f.text }}</small>
                        </div>
                        <div class='flex flex-col px-2 md:px-0 py-0 sm:flex-row items-center h-12'>
                          <div class="flex w-full md:w-1/2">
                            <span class='text-sm text-white bg-red-600 md:block py-2 px-4 rounded-full'>
                              {{ f.subTitle }}
                            </span>
                            <span
                              v-for='(tag, t) in f.tags'
                              :key='t'
                              class='text-gray-600 bg-gray-100 text-sm md:block py-2 px-2 rounded-full ml-2'
                            >
                              {{ tag }}
                            </span>
                          </div>
                          <div class="flex justify-end w-full md:w-1/2">
                            <div
                              v-for='(d, j) in f.buttons'
                              :key='j'
                            >
                              <div
                                v-if='d.canDownload'
                                class='text-sm text-white mt-1 md:mt-0 mr-1 bg-blue-600 md:block py-2 px-4 rounded-full hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                              >
                                <a
                                  :href='d.download'
                                  target='_blank'
                                >
                                  {{ d.downloadTxt }}
                                </a>
                              </div>
                              <div
                                @click='showRequestModal(f)'
                                v-else
                                class='text-sm text-white mt-1 md:mt-0 mr-1 bg-blue-600 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
                              >
                                {{ d.downloadTxt }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Card -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- col -->
      </div>
      <!-- More Events -->
      <!-- Header -->
    </div>
    <!-- brief intro -->
    <div class='flex flex-col subheader01_top'>
      <div class='w-full max-w-8xl py-6 px-4 mt-10 mb-20 self-center md:rounded-full shadow-lg bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100'>
        <div class="px-4 py-8 mx-auto sm:max-w-xl md:max-w-7xl md:px-24 md:py-0">
          <div class="max-w-xl mb-2 md:mx-auto sm:text-center md:max-w-7xl md:mt-8">
            <div>
              <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                Knowing us
              </p>
            </div>
            <h2 class="max-w-4xl mb-6 font-sans text-3xl font-medium leading-none tracking-tight text-gray-900 md:text-3xl md:mx-auto">
              <span class="relative inline-block">
                <svg viewBox="0 0 52 24" fill="#f6dda2" class="absolute top-40 left-50 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="d5589eeb-3fca-4f01-ac3e-983224745704" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect fill="url(#d5589eeb-3fca-4f01-ac3e-983224745704)" width="52" height="24"></rect>
                </svg>
                <span class="relative font-bold">MRC-Asia</span>
              </span>
              is an independent consulting firm specializing in total rewards & organisational strategy & predictive analytics consulting, using machine learning to bridge data science and remuneration.
            </h2>
          </div>
          <div class="relative w-full p-px mx-auto mb-4 overflow-hidden transition-shadow duration-300 md:mb-12 md:max-w-5xl group hover:shadow-xl rounded-xl">
            <div class="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
            <div class="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100"></div>
            <div class="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
            <div class="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100"></div>
            <div class="relative flex flex-col items-center h-full py-10 duration-300 bg-white rounded-sm transition-color sm:items-stretch sm:flex-row">
              <!-- Milestone 1 -->
              <div class="px-12 py-8 text-center">
                <h6 class="text-4xl font-bold text-deep-purple-accent-400 sm:text-5xl">
                  22
                </h6>
                <h6 class="text-xl font-bold text-deep-purple-accent-400 md:text-xl">
                  Years
                </h6>
                <p class="text-center md:text-base">
                  experience in HR C&B consulting surveys and projects.
                </p>
              </div>
              <div class="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:h-auto sm:w-1"></div>
               <!-- Milestone 2 -->
              <div class="px-12 py-8 text-center">
                <h6 class="text-4xl font-bold text-deep-purple-accent-400 sm:text-5xl">
                  300
                </h6>
                <h6 class="text-xl font-bold text-deep-purple-accent-400 md:text-xl">
                  Seminars/workshops
                </h6>
                <p class="text-center md:text-base">
                  conducted. Attended by 3k plus participants over 20 years.
                </p>
              </div>
              <div class="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:h-auto sm:w-1"></div>
               <!-- Milestone 3 -->
              <div class="px-12 py-8 text-center">
                <h6 class="text-4xl font-bold text-deep-purple-accent-400 sm:text-5xl">
                  2.7K
                </h6>
                <h6 class="text-xl font-bold text-deep-purple-accent-400 md:text-xl">
                  Multinational companies
                </h6>
                <p class="text-center md:text-base">
                  who participate in the surveys for 77 countries over the years.
                </p>
              </div>
              <div class="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:h-auto sm:w-1"></div>
               <!-- Milestone 4 -->
              <div class="px-12 py-8 text-center">
                <h6 class="text-4xl font-bold text-deep-purple-accent-400 sm:text-5xl">
                  1.3K
                </h6>
                <h6 class="text-xl font-bold text-deep-purple-accent-400 md:text-xl">
                  Positions title
                </h6>
                <p class="text-center md:text-base">
                  around the world is available to search in our Super-comp module.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- brief intro -->
    <!-- Online -->
    <div class='bg-gray-100 flex flex-col subheader01_mid h-40'>
      <div class='w-full max-w-6xl py-6 px-4 self-center'>
        <h2 class='text-5xl font-header p-4'>
          MRC Online Services
        </h2>
      </div>
    </div>
    <div class='bg-gray-100 flex flex-col pb-8 subheader01_bottom'>
      <div class='w-full max-w-6xl py-6 px-4 self-center'>
        <div class='flex flex-wrap -m-4'>
          <div class='flex flex-row w-full'>
            <div class="mt-5 w-full md:w-1/2">
              <router-link
                class='block px-4 py-1 rounded-lg hover:border hover:border-pink-200 hover:border-b-0 hover:shadow-lg'
                v-for='(ol, i) in onlineServices'
                :key='i'
                :to='ol.descurl'
              >
                <div class='flex flex-col items-center justify-center h-full text-center sm:flex-row sm:justify-start sm:text-left'>
                  <img
                    class='flex-shrink-0 object-cover object-center w-12 h-12 mb-4 rounded-lg sm:mb-0'
                    :src='ol.icon'
                    :alt='ol.title'
                  />
                  <div class='flex-grow w-10/12 sm:pl-8'>
                    <h2 class='text-lg font-medium text-gray-900 title-font'>
                      {{ ol.title }}
                    </h2>
                    <h3 class='mb-3 text-gray-500'>
                      {{ ol.desc }}
                    </h3>
                  </div>
                  <div class="flex w-1/12 h-full" v-if = "ol.title !='Performance Appraisal System'">
                    <img src='../assets/arrowlink.svg' class="w-8 h-8" />
                  </div>
                </div>
              </router-link>
            </div>
            <div class='flex justify-center items-center w-full md:w-1/2 h-full hidden md:flex'>
              <div class="relative w-1/2 h-1/2">
                <div class="absolute w-96 h-80 -top-24 -left-20 bg-white flex justify-center items-center transform transition-all">
                  <div class="flex-col h-full">
                    <div class="h-1/6 bg-black text-white flex justify-end items-center pr-2">
                      <img src='../assets/icons/windows/hyphen-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                      <img src='../assets/icons/windows/checkboxempty-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                      <img src='../assets/icons/windows/x-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                    </div>
                    <div class="h-5/6">
                      <img src='../assets/img/online/online01.jpg' class="h-full shadow-2xl" />
                    </div>
                  </div>
                </div>
                <div class="absolute w-96 h-80 top-8 left-8 bg-white flex justify-center items-center transform transition-all">
                  <div class="flex-col h-full">
                    <div class="h-1/6 bg-black text-white flex justify-end items-center pr-2">
                      <img src='../assets/icons/windows/hyphen-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                      <img src='../assets/icons/windows/checkboxempty-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                      <img src='../assets/icons/windows/x-svgrepo-com.svg' class="h-6 w-6 bg-white" />
                    </div>
                    <div class="h-5/6">
                      <img src='../assets/img/online/online02.jpg' class="h-full shadow-2xl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='py-4 space-x-4 w-full flex flex-row justify-center'>
            <div
              class='text-sm text-white ml-2 bg-blue-600 md:block py-2 px-4 rounded-full hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
            >
              <a
                href='/member'
              >
                Member Login
              </a>
            </div>
            <div
              @click='showRequestModal("MRC Online System")'
              class='text-sm text-black bg-white border border-gray-300 ml-2 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg'
            >
              Enquiry
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Online -->
    <!-- Consulting -->
    <div style="background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,244,246,1) 100%)" class="flex flex-col h-20"></div>
    <div class='relative flex flex-col subheader02_mid h-40'>
      <div class='w-full max-w-6xl py-6 px-4 self-center'>
        <h2 class='text-5xl font-header p-4'>
          Consulting Services
        </h2>
      </div>
    </div>
    <div class='flex flex-col py-4'>
      <div class='bg-white w-full max-w-6xl self-center py-6 rounded-sm'>
        <div class='flex flex-row'>
          <!-- Pictures -->
          <div class='relative justify-center items-center hidden md:flex ml-10'>
            <div class="absolute w-128 h-4/5">
              <div class="w-full h-full bg-red-800 flex justify-center items-center transform transition-all absolute rounded-lg opacity-90">
                <img src="../assets/img/consultant/consultant01.jpg" class="w-full h-full" />
              </div>
              <div class="w-full h-full bg-blue-400 flex justify-center items-center transform transition-all absolute rounded-lg rotate-12 opacity-80">
                <img src="../assets/img/consultant/consultant03.jpg" class="w-full h-full" />
              </div>
              <div class="w-full h-full bg-black flex justify-center items-center transform transition-all rounded-lg -rotate-12 opacity-60">
                <img src="../assets/img/consultant/consultant02.jpg" class="w-full h-full" />
              </div>
            </div>
          </div>
          <!-- Pictures -->
          <div class='grid grid-cols-1 md:grid-cols-2 gap-4 w-full mx-2 md:ml-40'>
            <!-- (service, i) in services.slice(0, services.length / 2 + 1) -->
            <!-- (service, i) in services.slice(services.length / 2 + 1) -->
            <router-link
              class='relative block overflow-hidden rounded-lg border border-gray-100 hover:border-pink-200 hover:border-b-0 hover:shadow-lg'
              v-for='(service, i) in services'
              :key='i'
              :to='service.url'
            >
              <div class="bg-white h-full p-4" v-if="i%2==0">
                <span
                  class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-white via-green-500 to-yellow-600"
                ></span>

                <div class="flex sm:justify-between sm:gap-4">
                  <div class="w-4/5">
                    <h3 class="text-lg font-bold text-gray-900 sm:text-xl">
                      {{ service.title }}
                    </h3>
                  </div>

                  <div class="hidden sm:block sm:shrink-0 h-14 w-14">
                    <img
                      :src="service.icon"
                      class="rounded-lg object-cover shadow-sm"
                    />
                  </div>
                </div>

                <div class="mt-3">
                  <p class="max-w-[40ch] text-base text-gray-500">
                    {{ service.text }} <img src='../assets/arrowlink.svg' class="w-8 h-8 inline float-right" />
                  </p>
                </div>
              </div>
              <div class="bg-white h-full p-4" v-else>
                <span
                  class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-yellow-100 via-green-500 to-red-600"
                ></span>

                <div class="flex sm:justify-between sm:gap-4">
                  <div class="w-4/5">
                    <h3 class="text-lg font-bold text-gray-900 sm:text-xl">
                      {{ service.title }}
                    </h3>
                  </div>

                  <div class="hidden sm:block sm:shrink-0 h-14 w-14">
                    <img
                      :src="service.icon"
                      class="rounded-lg object-cover shadow-sm"
                    />
                  </div>
                </div>

                <div class="mt-3">
                  <p class="max-w-[40ch] text-base text-gray-500">
                    {{ service.text }} <img src='../assets/arrowlink.svg' class="w-8 h-8 inline float-right" />
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
            <!-- Consulting -->
            <Testimonials />
    <RequestBrochureModal
      v-model='requestModalVisible'
      :request.sync='brochureRequest'
      @request-sent='requestSent'
    />
    <Alert
      bottom
      :timeout='8000'
      v-model='subscriptionAlert'
    >
      <div class='px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm justify-between'>
        <div class='pr-6'>
          {{ subscriptionMsg }}
        </div>
        <button
          @click='subscriptionAlert = false'
          class='text-pink-500 focus:outline-none font-medium text-sm'
        >
          CLOSE
        </button>
      </div>
    </Alert>
  </div>
</template>

<style scoped>
.banner {
  background-image: url('../assets/img/home_ai_banner.png');
}

.services {
  background-image: url('../assets/img/home_money_plant.jpg');
}

.subheader01_top {
  background: url('../assets/img/background/subheader01_top.jpg') bottom no-repeat;
}
.subheader01_mid {
  background: url('../assets/img/background/subheader01_mid.jpg') no-repeat;
}
.subheader01_bottom {
  background: rgb(243 244 246) url('../assets/img/background/subheader01_bottom.jpg') top no-repeat;
}

.subheader02_mid {
  background: url('../assets/img/background/subheader02_mid.jpg') bottom no-repeat;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';
import RequestBrochureModal from '@/components/RequestBrochureModal.vue';
import Alert from '@/components/Alert.vue';
import Testimonials from '../components/TestimonialsHome.vue';

export default {
  name: 'Home',
  methods: {
    getFeaturesMore(highlight) {
      if (highlight === 'Events') {
        return this.featuresEvents;
      }
      if (highlight === 'Books') {
        return this.featuresBooks;
      }
      if (highlight === 'Elaine Ng/MRC') {
        return this.featuresElaine;
      }
      return this.features;
    },
    showRequestModal(seminar) {
      if (seminar.title !== undefined) {
        this.brochureRequest = `Request for ${seminar.title} brochure`;
      } else {
        this.brochureRequest = `Enquiry for ${seminar}: `;
      }
      this.requestModalVisible = true;
    },
    requestSent() {
      this.requestModalVisible = false;
      this.subscriptionMsg = 'Your request is sent successfully! Our consultants will contact you soon.';
      this.subscriptionAlert = true;
    },
  },
  data() {
    return {
      startIndex: 0,
      showPagination: true,
      hTextshow1: false,
      hTextshow2: false,
      moreHighlights: ['Events', 'Books', 'Elaine Ng/MRC'],
      selectedHighlight: 'Events',
      subscriptionAlert: false,
      subscriptionMsg: '',
      requestModalVisible: false,
      brochureRequest: '',
      options: Object.freeze({
        currentPage: 0,
        tracking: false,
        thresholdDistance: 100,
        thresholdTime: 300,
        infinite: 2,
        slidesToScroll: 2,
        loop: true,
      }),
      features: Object.freeze([
        {
          img: '/img/featured/swiper_paytrend.jpg',
          title: 'DESIGNING SALES COMMISSION, SALES BONUS & SALES INCENTIVE (Online: 17-18 Feb)',
          subTitle: 'Feb 2025 ',
          text: '',
          buttons: [
            {
              downloadTxt: 'Download',
              download: '/api/file/MRC_Designing_Sales_Commission_Sales_Bonus_Sales_Incentive_Online_Seminar_2025.pdf',
              canDownload: true,
            },
          ],
          tags: ['#Sales Commission'],
        },
      ]),
      featuresArticles: Object.freeze([
        {
          img: '',
          title: 'APAC LABOUR MARKET & PAY RISE OUTLOOK - IS 2025 BUDGET FALLING?',
          subTitle: 'January 2025 Edition',
          text: '',
          buttons: [
            {
              downloadTxt: 'Read Article',
              download: '/market_insights/ebi/APAC LABOUR MARKET AND PAY RISE OUTLOOK',
              canDownload: true,
            },
          ],
          tags: ['#Executive Business Intelligence'],
        },
        {
          img: '',
          title: 'HOW SHOULD YOU PAY YOUR C-SUITE EXECUTIVES? - EXEC COMP TRENDS IN APAC',
          subTitle: 'November - December 2024 Edition',
          text: '',
          buttons: [
            {
              downloadTxt: 'Read Article',
              download: '/market_insights/ebi/HOW SHOULD YOU PAY YOUR C-SUITE EXECUTIVES',
              canDownload: true,
            },
          ],
          tags: ['#Executive Business Intelligence'],
        },
      ]),
      featuresEvents: Object.freeze([
        {
          img: '/img/featured/sq_designingremuneration.jpg',
          title: 'DESIGNING REMUNERATION & BONUS: PAY FOR POSITION,PERSON & PERFORMANCE (Online: 09-11 Apr)',
          subTitle: 'Apr2025 ',
          text: '',
          buttons: [
            {
              downloadTxt: 'Request Brochure',
              download: '/api/file/.pdf',
              canDownload: false,
            },
          ],
          tags: ['#Remuneration'],
        },
        {
          img: '/img/featured/sq_calendar.jpg',
          title: '2025 ACTIVITY CALENDAR',
          subTitle: 'Year 2025 ',
          text: '',
          buttons: [
            {
              downloadTxt: 'Download',
              download: '/api/file/MRC_Activity Calendar 2025.pdf',
              canDownload: true,
            },
          ],
          tags: ['#calendar'],
        },
      ]),
      featuresBooks: Object.freeze([
        {
          img: '/img/featured/sq_IAmWhoIAm.png',
          title: 'I Am Who I Am - The story of my God',
          subTitle: 'Released 2024',
          text: '',
          buttons: [
            {
              downloadTxt: 'Click To Buy',
              download: '/book',
              canDownload: false,
            },
          ],
          tags: ['#Book'],
        },
        {
          img: '/img/featured/sq_InTheShadowsOfDarkness.jpg',
          title: 'In The Shadows Of Darkness',
          subTitle: 'Released 2023',
          text: '',
          buttons: [
            {
              downloadTxt: 'Click To Buy',
              download: '/book',
              canDownload: false,
            },
          ],
          tags: ['#Book'],
        },
        {
          img: '/img/featured/sq_book.jpg',
          title: 'Purchase I AM UNTAUGHT hardcopy book',
          subTitle: 'Released 2022',
          text: '',
          buttons: [
            {
              downloadTxt: 'Click To Buy',
              download: '/book',
              canDownload: true,
            },
          ],
          tags: ['#Book'],
        },
        {
          img: '/img/featured/sq_ebook.jpg',
          title: 'Purchase I AM UNTAUGHT e-book',
          subTitle: 'Released 2022',
          text: '',
          buttons: [
            {
              downloadTxt: 'Click To Buy',
              download: '/book',
              canDownload: true,
            },
          ],
          tags: ['#e-book'],
        },
        {
          img: '/img/featured/sq_book_givingup.jpg',
          title: 'GIVING UP SALARY – STARTING HRBS & SELLING IT TO MERCER',
          subTitle: 'Released 2017',
          text: 'Access via various Singapore national libraries, Non-Fiction, Code: 650.1',
          buttons: [
            {
              downloadTxt: 'Access Now',
              download: 'https://catalogue.nlb.gov.sg/cgi-bin/spydus.exe/ENQ/WPAC/BIBENQ?optionsDrop=Full+Catalogue&ENTRY=giving+up+salary+%3A+starting+hrbs+%26+selling+it+to+mercer&ENTRY_NAME=BS&ENTRY_TYPE=K&SORTS=SQL_REL_BIB&GQ=giving+up+salary+%3A+starting+hrbs+%26+selling+it+to+mercer&ISGLB=0&NRECS=20&QRY=&QRYTEXT=',
              canDownload: true,
            },
          ],
          tags: ['#book'],
        },
      ]),
      featuresElaine: Object.freeze([
        {
          img: '/img/featured/sq_award2023.jpg',
          title: 'MRC won "Most Outstanding Remuneration & Predictive Analytics Consulting Firm"',
          subTitle: 'Sep 2023',
          text: '',
          buttons: [
            {
              downloadTxt: 'View More',
              download: 'https://www.linkedin.com/feed/update/urn:li:activity:7112650966021124097',
              canDownload: true,
            },
          ],
          tags: ['#HKEA2023'],
        },
        {
          img: '/img/featured/sq_videobook.jpg',
          title: 'Elaine Ng book launch event videos',
          subTitle: 'Oct 2022',
          text: '',
          buttons: [
            {
              downloadTxt: 'View Video',
              download: 'https://www.youtube.com/playlist?list=PLN-RQMX9eQMo4dlbVmSbNRRp20xWgoOdI',
              canDownload: true,
            },
          ],
          tags: ['#video'],
        },
        {
          img: '/img/featured/sq_award.jpg',
          title: 'MRC won "Best Startup HR Award"',
          subTitle: 'Nov 2018',
          text: '',
          buttons: [
            {
              downloadTxt: 'View Photos',
              download: 'https://www.flickr.com/photos/138568386@N02/albums/72157701042286062',
              canDownload: true,
            },
          ],
          tags: ['#photos'],
        },
      ]),
      onlineServices: Object.freeze([
        {
          title: 'Executive Compensation Public',
          desc: 'Board of Directors’ Pay for listed companies from annual reports.',
          descurl: '/tools/executive_compensation_public',
          url: '/exec-comp/',
          permissions: ['exec_comp_public'],
          img: '/img/online_services/1.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ec_public2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Executive Compensation Private',
          desc: 'Yearly survey data from private and public-listed companies.',
          descurl: '/tools/executive_compensation_private',
          url: '/exec-comp-private/',
          permissions: ['exec_comp_private'],
          img: '/img/online_services/2.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ec_private2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Super Compensation',
          desc: 'Salary data powered by MRC machine learning algorithm.',
          descurl: '/tools/super_compensation',
          url: '/super-comp/',
          permissions: ['super_comp'],
          img: '/img/online_services/3.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/supercomp.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Employee Benefits',
          desc: 'View benefits policies in the market across APAC.',
          descurl: '/tools/benefits_data_services',
          url: '/employee-benefits/',
          permissions: ['employee_benefits'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/benefit2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'APAC Market Insights',
          desc: 'Bi-annual survey, provides guides on APAC economics (GDP, inflation, unemployment, FDI, PMI) & HR guide (pay increase, attrition, workforce demand, bonus, fresh grad start rates).',
          descurl: '/market_insights',
          url: '/mi/',
          permissions: ['mi_survey'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/mi_survey.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Sales Incentive Practices',
          desc: 'Provides an update of the latest sales commission/ bonus/ incentive trends covering 15 APAC locations, and provides insights into country-specific practices.',
          descurl: '/tools/sale_incentive_report',
          url: '/sip/',
          permissions: ['sip'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/sip.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Job Evaluation System',
          desc: 'Evaluate jobs and benchmark it againts the market.',
          descurl: '/home/compensation_benchmarking',
          url: '/jes/',
          permissions: ['jes'],
          img: '/img/online_services/5.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/jes.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'AI Market Sensor for HR',
          desc: 'Pay Trends, Forecast Predictor, Employment Legislations, Relocation Calculator, Productivity & Financials, HR Market, and Economy.',
          descurl: '/tools/pay_trends',
          url: '/aims/',
          permissions: [
            'aims.nonrewards',
            'mds',
            'forecast_calculator',
            'hr_markets',
            'pay_trends',
            'relocation',
            'legislations_news',
            'finance_productivity',
            'economy',
            'personnel-expense',
          ],
          img: '/img/online_services/6.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/aims.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Employee Risk Predictor',
          desc: 'Predict individual resignations with HR adjustable dashboards.',
          descurl: '/ai/employee_risk_predictor',
          url: '/erp/',
          permissions: ['erp'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/risk.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'AI HR Writer',
          desc: 'Write job description, recruitment ads post and hr related letter includes call-for-interview, onboarding, promotion, salary increase by AI powered tools.',
          descurl: '/ai/ai_content_generator',
          url: '/content-guide/',
          permissions: ['content_guide'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ai_hr_writer.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Performance Appraisal System',
          desc: 'Appraise employee performance, and determine and allocate salary increases and performance bonuses',
          descurl: '',
          url: '/pas/',
          permissions: ['pas_appraiser'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/pas.svg'),
          color: 'bg-gray-200',
          newWindow: false,
        },
        {
          title: 'Monthly Market Insights',
          desc: 'Download monthly C&B market insights.',
          descurl: '/market_insights',
          url: '/market_insights_members/',
          permissions: ['contrarian_view', 'econ_guide', 'legis_guide'],
          img: '/img/online_services/8.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/marketinsight.svg'),
          color: 'bg-gray-200',
          newWindow: false,
        },
        {
          title: 'Seminars / Symposium / Retail Forum Materials',
          desc: 'Download seminar materials and re-watch the seminar video.',
          descurl: '/seminars',
          url: '/seminar_members/',
          permissions: ['seminar_material'],
          img: '/img/online_services/9.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/seminar.svg'),
          color: 'bg-gray-200',
          newWindow: false,
        },
      ]),
      services: Object.freeze([
        {
          url: '/home/compensation_benchmarking',
          title: 'Compensation Benchmarking',
          text: 'Job evaluation, pay benchmarking and salary structure design.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/compbenchmark.svg'),
        },
        {
          url: '/home/executive_compensation',
          title: 'Executive Compensation Review & Recommendation Service',
          text: 'Salary, bonus, benefits, stock and perks recommendation.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/executive.svg'),
        },
        {
          url: '/home/directors_remuneration',
          title: 'Board of Directors’ Remuneration Advisory',
          text: 'Directors` fees and remuneration package for board of directors powered by MRC listed companies` database.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/directors.svg'),
        },
        {
          url: '/home/sales_incentive_plan',
          title: 'Sales Incentive Plan Design',
          text: 'Commission, incentive, bonus and benefits.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/incentive.svg'),
        },
        {
          url: '/home/stock_plan',
          title: 'Stock, Phantom Stock & Deferred Cash Plan Design',
          text: 'Equity-settled / cash-settled share-based incentive plan design.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/stock.svg'),
        },
        {
          url: '/home/performance_appraisal',
          title: 'Performance Appraisal and Bonus Distribution',
          text: 'Restructure performance appraisal and bonus distribution approach.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/appraisal.svg'),
        },
        {
          url: '/home/relocation',
          title: 'Relocation Package and Mobility Policy',
          text: 'COLA, hardship, mobility, children education, housing, tax, localisation, pension, home passage and other mobility policies recommendation.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/relocation.svg'),
        },
        {
          url: '/home/ma_compensation_benefits_integration',
          title: 'M&A Compensation and Benefits Integration',
          text: 'Definitive agreement, equity compensation, employee compensation & benefits, integration assistance.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/integration.svg'),
        },
        {
          url: '/home/org_design',
          title: 'Organisation Design',
          text: 'Strategic organisation design and workforce planning powered by MRC benchmarking database & Job Evaluation System (JES).',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/orgdesign.svg'),
        },
        {
          title: 'Talent Management',
          text: 'Attract, retain and develop your skilled talents. Powered by MRC A.I. Tools monitoring the talent trends across the globe.',
          url: '/home/talent_management',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/talentmanage.svg'),
        },
        {
          title: 'Market Research',
          text: 'Research on the right destination to start your new operation.',
          url: '/home/market_research',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/consultant/marketresearch.svg'),
        },
      ]),
    };
  },
  mounted() {
    setInterval(() => {
      this.hTextshow1 = true;
    }, 3000);
    setInterval(() => {
      this.hTextshow2 = true;
    }, 3500);
  },
  components: {
    Testimonials,
    RequestBrochureModal,
    Alert,
    Carousel,
    Slide,
  },
};
</script>
